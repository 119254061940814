import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Card1 from "../components/card-project-1"
import Card2 from "../components/card-project-2"
import Card3 from "../components/card-project-3"
import Card4 from "../components/card-project-4"
import Card5 from "../components/card-project-5"
import Card6 from "../components/card-project-6"
import Card7 from "../components/card-project-7"
import "../components/global.css"

const IndexPage = () => (
  <Layout>
    <div className="work-grid">
      <Card1 />
      <Card6 />
      <Card2 />
      <Card3 />
      <Card4 />
      <Card7 />
      <Card5 />
    </div>
  </Layout>
)

export default IndexPage

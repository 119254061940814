import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import "./global.css"
import logo from "../images/mytennisroster/mytennisroster.svg"
import styled from "styled-components"

const Wrapper = styled.section`
  .projectLogo {
    transform: scale(1.5);
    transform-origin: left;
  }
`

const Card = () => (
  <Link className="card-link" to="/work/mytennisroster">
    <Wrapper>
      <img className="projectLogo" alt="images" src={logo} />
    </Wrapper>
    <p>Roster Management Mobile App</p>
    <div className="wrap">
      <div className="cover"></div>
      <StaticImage
        src="../images/mytennisroster/mytennisroster-card.jpg"
        alt="Project"
        placeholder="dominantColor"
        className="card-img"
      />
    </div>
  </Link>
)

export default Card

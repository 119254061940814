import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import "./global.css"
import logo from "../images/oxygenLogo.svg"

const Card = () => (
  <Link className="card-link" to="/work/oxygen/">
    <img className="projectLogo" alt="images" src={logo} />
    <p>Brand Architecture</p>
    <div className="wrap">
      <div className="cover"></div>
      <StaticImage
        src="../images/oxygenCard.jpg"
        alt="Project"
        placeholder="dominantColor"
        className="card-img"
      />
    </div>
  </Link>
)

export default Card

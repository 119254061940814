import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import "./global.css"
import logo from "../images/oxygenLogo.svg"

const Card = () => (
  <Link className="card-link" to="/work/oxygen-learning">
    <img className="projectLogo" alt="images" src={logo} />
    <p>experience design</p>
    <div className="wrap">
      <div className="cover"></div>
      <StaticImage
        src="../images/oxygen-learning/oxygen-learning-card.jpg"
        alt="Project"
        placeholder="dominantColor"
        className="card-img"
      />
    </div>
  </Link>
)

export default Card
